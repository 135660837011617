@import 'tailwind.css';
@import 'links.css';
@import 'font.css';
@import 'animation.css';
@import 'nprogress.css';
@import 'article.css';
@import 'newsletter.css';
@import 'gravity-forms.css';
@import 'embla.css';
@import 'ads.css';
@import 'gallery.css';

html {
    @apply font-body bg-white;
    color: #110b29;
}

.top-bar {
    @apply flex-grow-0 flex-shrink-0 text-xs text-left text-white flex justify-center items-center w-full relative gap-2.5 p-2.5;
}

.top-bar a {
    @apply underline font-semibold;
}
.top-bar a:hover {
    @apply text-purple-100 no-underline;
}

.post-content p {
    @apply mt-4 mb-4;
}

.post-content img {
    @apply w-full rounded-lg;
}

.toc-level {
    @apply pl-8 list-disc;
}

.toc-item a.page-link {
    @apply text-sm text-gray-600 leading-6 hover:text-black;
}

.markdown p {
    @apply m-0;
}

.markdown h1 {
    @apply text-base font-semibold;
}

.pulse-purple {
    animation: animate-pulse-purple 2s infinite;
}

.skeleton-line {
    @apply relative overflow-hidden bg-gray-200;
}

.skeleton-line::after {
    @apply absolute inset-0;
    transform: translateX(-100%);
    background-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0,
        rgba(255, 255, 255, 0.2) 20%,
        rgba(255, 255, 255, 0.5) 60%,
        rgba(255, 255, 255, 0)
    );
    animation: shimmer 3s infinite;
    content: '';
}

.scrollbar-hide {
    /* IE and Edge */
    -ms-overflow-style: none;

    /* Firefox */
    scrollbar-width: none;
}

/* Safari and Chrome */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

@-webkit-keyframes shimmer {
    100% {
        transform: translateX(100%);
    }
}

@-webkit-keyframes animate-pulse-purple {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgb(175, 151, 253);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
}

.top-ticker {
    --gap: 1rem;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);
}

.top-ticker .top-ticker-items {
    flex-shrink: 0;
    display: flex;
    gap: var(--gap);
}

.top-ticker:hover > div,
.top-ticker:hover > .top-ticker-items.top-ticker-items-second {
    animation-play-state: paused;
}

@media (prefers-reduced-motion: reduce) {
    .top-ticker .top-ticker-items {
        animation-play-state: paused !important;
        animation: none;
    }
}

.top-ticker .top-ticker-items {
    animation: marquee 56s linear infinite;
}
.top-ticker .top-ticker-items.top-ticker-items-second {
    animation: marquee 56.4s linear infinite;
    background: white;
}

@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

.emoji-404 {
    position: relative;
    animation: emojimove 2.5s infinite;
}

@keyframes emojimove {
    33% {
        top: 0;
    }
    66% {
        top: 20px;
    }
    100% {
        top: 0px;
    }
}

.touch-scroll {
    -webkit-overflow-scrolling: touch; /* [3] */
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.touch-scroll::-webkit-scrollbar {
    display: none;
}
